<template>
  <div class="box p-4 mt-2 flex justify-between">
    <div class="flex flex-col font-bold justify-center">
      <span>Numero de radicado</span>
      <span class="text-2xl">{{ data.numRadicado }}
        <i v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" class="pi pi-pencil text-xl text-red-600 cursor-pointer" @click="editar" />
      </span>
    </div>
    <div>
      <div>
          <p class="font-bold text-base">
            Información del juzgado
          </p>
          <div class="mt-2">
            <div class="flex justify-between mt-2">
              <div class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z">
                  </path>
                </svg>
              </div>
              <div class="flex justify-end">
                <p class="text-sm text-black font-medium text-right">
                  {{ data.juzgado.categoria.nombre }} {{ data.juzgado.code }} {{ data.juzgado.nombre }} DE {{ data.juzgado.localizacion }}
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="flex flex-col justify-center gap-2">
      <div class="flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full mr-2" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"/>
        </svg>
        <span class="font-bold">Fecha de notificacion</span>
        <span>{{ data.fechaRecepcion }} {{ data.horaRecepcion }}</span>
      </div>
      <div class="flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full mr-2" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"/>
        </svg>
        <span class="font-bold">Fecha de vencimiento</span>
        <span>{{ data.fechaVencimiento }} {{ data.horaVencimiento }}</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col md:gap-2 md:flex-row">
    <!--    COLUMNA #1-->
    <div class="flex flex-col mt-2 w-full">
      <section class="box p-6">
        <span class="font-bold text-lg">{{ data.accionante.fullname }}</span>
        <div class="flex justify-between mt-2">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;"
                 class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="2">
              <path class="" stroke-linecap="round" stroke-linejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
            </svg>
            <span class="text-sm text-gray-500 font-medium">Email</span>
          </div>
          <span class="font-bold">{{ data.accionante.correo }}</span>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;"
                 class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"/>
            </svg>
            <span class="text-sm text-gray-500 font-medium">Identificacion</span>
          </div>
          <span class="font-bold">{{ data.accionante.docTipoId }} {{ data.accionante.numeroIdentificacion }}</span>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;"
                 class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
            </svg>
            <span class="text-sm text-gray-500 font-medium">Teléfono</span>
          </div>
          <span class="font-bold">{{ data.accionante.telefono }}</span>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;"
                 class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                 stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
            </svg>
            <span class="text-sm text-gray-500 font-medium">Localizacion</span>
          </div>
          <span class="font-bold">{{ data.accionante.localizacion }}</span>
        </div>
        <div class="flex justify-between mt-2">
          <div class="flex items-center">
            <i class="pi pi-home text-blue-600 ml-1 mr-2 text-xs"></i>
            <span class="text-sm text-gray-500 font-medium">Dirección</span>
          </div>
          <span class="font-bold">{{ data.accionante.direccion }}</span>
        </div>
        <div v-if="data.accionante.representanteLegal" class="flex justify-between mt-2">
          <div class="flex items-center">
            <i class="pi pi-users text-blue-600 ml-1 mr-2 text-xs"></i>
            <span class="text-sm text-gray-500 font-medium">Representante Legal</span>
          </div>
          <span class="font-bold">{{ data.accionante.representanteLegal }}</span>
        </div>
      </section>
      <section class="box p-6 mt-2">
        <p class="font-bold text-base">
          Solicitud
        </p>

        <div class="flex justify-between mt-2">
          <p>{{ data.observaciones }}</p>
        </div>
      </section>
      <section class="box p-6 mt-2">
        <div class="flex justify-between">
          <p class="font-bold text-base">
            Soportes
          </p>
          <div v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" v-tooltip="'Agregar soporte'" @click="openMaximizable()">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 1024 1024"
                 class="cursor-pointer text-blue-600"
            >
              <path fill="currentColor"
                    d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"/>
            </svg>
          </div>
        </div>

        <div class="flex justify-between mt-2" v-for="(item, index) in data.soportes" :key="index">
          <div class="flex">
            <i class="pi pi-file-pdf mr-2 text-red-600"></i>
            <span>{{ item.fileName }}</span>
          </div>
          <button @click="verPdf(item.urlPath)" class="ease-in duration-300" >
            <svg xmlns="http://www.w3.org/2000/svg" style="padding: 3px;" class="h-5 w-5 text-blue-600 rounded-full mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
          </button>
        </div>

      </section>
    </div>
    <!--    COLUMNA #2-->
    <div class="flex flex-col mt-2 w-full">
      <section v-if="data.provisonalidad" class="box p-6 mb-2">
        <div class="flex items-center justify-between mb-4">
          <div>
            <p class="font-bold text-base">
              Medida provisional
            </p>
          </div>
          <div v-if="!data.respuestas.some(a => a.provisionalidad === true)">
            <Button label="Ingresar cumplimiento Medida Provisional" @click="$router.push({name:'pharmasan.administrativa.juridica.respuestas.crear', query: { base: route.params.id, tipo: 1, provisionalidad: true }})"></Button>
          </div>
        </div>
        <div>
          <div class="flex justify-between">
            <div class="text-sm text-gray-500 font-medium">
              Fecha y hora:
            </div>
            <div class="font-medium">
              {{ data.provisonalidadFecha }} {{ data.provisonalidadHora }}
            </div>
          </div>
          <div v-for="(respuestaProvisional, m) in data.respuestas" :key="m">
            <div v-if="respuestaProvisional.provisionalidad === true">
              <div v-if="respuestaProvisional.provisionalidad === true" class="flex justify-between my-2">
                <div>
                  <p class="text-sm text-gray-500 font-medium">
                    Observacion
                  </p>
                </div>
                <div>
                  {{ respuestaProvisional.observacion }}
                </div>
              </div>
              <div class="flex justify-between my-2" v-for="(path, l) in respuestaProvisional.paths" :key="l">
                <div class="flex">
                  <i class="pi pi-file-pdf text-red-600 mr-2"></i>
                  <p>
                    {{ path.fileName }}
                  </p>
                </div>
                <div>
                  <button @click="verPdf(path.urlPath)">
                    <i class="pi pi-eye text-blue-600"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section v-if="find(data.respuestas, {'provisionalidad': false})" class="box p-6">
          <div class="flex justify-between">
            <p class="font-bold text-base">
              Respuesta de la tutela
            </p>
            <div v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" v-tooltip="'Agregar soporte'"
                 @click="openMaximizableRespuestas(data.respuestas)">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" preserveAspectRatio="xMidYMid meet"
                   viewBox="0 0 1024 1024"
                   class="cursor-pointer text-blue-600"
              >
                <path fill="currentColor"
                      d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"/>
              </svg>
            </div>
          </div>
          <div v-for="(respuesta, k) in data.respuestas" :key="k">
          <div v-if="!respuesta.provisionalidad">
            <div class="flex justify-between mt-4">
              <div>
                <p>
                  Respondido por: <span class="font-medium">{{ toString(respuesta.usuario.map(a => a.persona.full_name)) }}</span>
                </p>
              </div>
              <div>
                <p>
                  Fecha: <span class="font-medium">{{ dayjs(respuesta.createdAt).format("YYYY/MM/DD") }}</span>
                </p>
              </div>
            </div>
            <div class="my-2" v-for="(archivo, j) in respuesta.paths" :key="j">
              <div class="flex justify-between">
                <div>
                  <p>
                    {{ archivo.fileName }}
                  </p>
                </div>
                <div>
                  <button @click="verPdf(archivo.urlPath)">
                    <i class="pi pi-eye text-blue-600"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        <section v-else class="box p-6">
          <div>
            <div class="flex items-center justify-between mb-4">
              <div>
                <p class="font-bold text-base">
                  Tiempo para dar respuesta
                </p>
              </div>
              <Button label="Ingresar respuesta Acción de Tutela" @click="$router.push({name:'pharmasan.administrativa.juridica.respuestas.crear', query: { base: route.params.id, tipo: 1, provisionalidad: false }})"></Button>
            </div>
            <div class="justify-between flex mt-4" :class="textColor ? 'text-red-600' : ''">
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.days }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.days }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Dias</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.hours }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.hours }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Horas</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.minutes }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.minutes }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Minutos</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.seconds }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.seconds }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Segundos</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
    </template>
  </Dialog>

  <Dialog header="Agregar soportes" v-model:visible="displaySoportesRespuesta" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizableRespuesta" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import dayjs from 'dayjs'
import { toString, find } from 'lodash'
import FilePond from '../../../../uploadFilePoundNew/index.vue'
import filepondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'

export default {
  name: 'verTutela',
  components: {
    FilePond
  },
  setup () {
    const _PdfService = new PdfService()
    const displayMaximizable = ref(false)
    const displaySoportesRespuesta = ref(false)
    const route = useRoute()
    const router = useRouter()
    const idRespuesta = ref(null)
    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const openMaximizableRespuestas = (respuestas) => {
      idRespuesta.value = respuestas[0].id
      displaySoportesRespuesta.value = true
    }
    const pond = ref()
    const paths = ref([])
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const title = ref('')

    const data = computed(() => {
      return storeTutela.getters.showTutela
    })

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const contador = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })

    const lapso = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })

    const textColor = computed(() => {
      return dayjs(`${data.value.fechaVencimiento} ${data.value.horaVencimiento}`) < dayjs(`${data.value.fechaRecepcion} ${data.value.horaRecepcion}`)
    })

    const editar = () => {
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas.formulario.editar.datos-accionante', params: { id: route.params.id } })
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'tutela',
          tipo: 1,
          base: route.params.id,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        storeTutela.dispatch('onShow', route.params.id)
        displayMaximizable.value = false
        title.value = ''
        paths.value = []
      })
    }

    const closeMaximizableRespuesta = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'respuesta',
          tipo: 4,
          base: idRespuesta.value,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        storeTutela.dispatch('onShow', route.params.id)
        displaySoportesRespuesta.value = false
        title.value = ''
        paths.value = []
      })
    }

    setInterval(() => {
      const inicio = dayjs()
      const fin = dayjs(`${data.value.fechaVencimiento} ${data.value.horaVencimiento}`)
      const loquesea = dayjs.duration(fin - inicio)
      contador.value = (loquesea.$d)
    }, 1000)

    watch(data, (nuevo, viejo) => {
      if (nuevo) {
        lapso.value = dayjs.duration(dayjs(`${data.value.fechaVencimiento} ${data.value.horaVencimiento}`) - dayjs(`${data.value.fechaRecepcion} ${data.value.horaRecepcion}`)).$d
      }
    })

    onMounted(() => {
      storeTutela.dispatch('onShow', route.params.id)
    })

    return {
      data,
      contador,
      lapso,
      textColor,
      verPdf,
      displayMaximizable,
      displaySoportesRespuesta,
      editar,
      dayjs,
      toString,
      route,
      find,
      openMaximizable,
      closeMaximizableRespuesta,
      closeMaximizable,
      openMaximizableRespuestas,
      pond,
      title
    }
  }
}
</script>

<style scoped>
.letra-cuenta-regresiva {
  font-size: 2rem;
}
</style>
